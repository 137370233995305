import { ApiService } from '@lasso/api-shared'

import {
  AdUnitsCreateManyRequest,
  AdUnitsCreateUpdateRequest,
  AdUnitsCreateUpdateResponse,
  AdUnitsDeleteResponse,
  AdUnitsGetCsvResponse,
  AdUnitsGetManyCsvRequest,
  AdUnitsGetManyCsvResponse, AdUnitsListItem,
  AdUnitsListRequest,
  AdUnitsListResponse,
} from '../types'

export class AdUnitsService extends ApiService {
  async getAdUnitData(id: number): Promise<AdUnitsListItem> {
    return (await this.request({
      url: `api/monetization/adtags/adunit/${id}`,
      method: 'GET',
    })).data
  }

  async getAdUnitsData(data: AdUnitsListRequest): Promise<AdUnitsListResponse> {
    return (await this.request({
      url: 'api/monetization/adtags/adunits',
      method: 'POST',
      data,
    })).data
  }

  async deleteAdUnit(id: number): Promise<AdUnitsDeleteResponse> {
    return (await this.request({
      url: `api/monetization/adtags/adunit/${id}`,
      method: 'DELETE',
    })).data
  }

  async createAdUnit(data: AdUnitsCreateUpdateRequest): Promise<AdUnitsCreateUpdateResponse> {
    return (await this.request({
      url: 'api/monetization/adtags/adunit',
      method: 'POST',
      data,
    })).data
  }

  async updateAdUnit(id: number, data: AdUnitsCreateUpdateRequest): Promise<AdUnitsCreateUpdateResponse> {
    return (await this.request({
      url: `api/monetization/adtags/adunit/${id}`,
      method: 'POST',
      data,
    })).data
  }

  async getAdUnitCsv(id: number, eNewsIdentitySource: number, adUnitExportFormat: number): Promise<AdUnitsGetCsvResponse> {
    const data: AdUnitsGetManyCsvRequest = {
      ids: [id.toString()],
      eNewsIdentitySource,
      adUnitExportFormat,
    }
    return this.getAdUnitManyCsv(data)
  }

  async getAdUnitManyCsv(data: AdUnitsGetManyCsvRequest): Promise<AdUnitsGetManyCsvResponse> {
    return (await this.request({
      url: 'api/monetization/adtags/adunit/many/csv',
      method: 'POST',
      data,
    })).data
  }

  async createManyAdUnits(placementId: number, data: AdUnitsCreateManyRequest): Promise<AdUnitsGetCsvResponse> {
    return (await this.request({
      url: `api/monetization/adtags/placement/${placementId}/import-adunits`,
      method: 'POST',
      data,
    })).data
  }

  async uploadAdUnitCreative(id: number, file: File): Promise<unknown> {
    const formData = new FormData()
    formData.set('file', file)

    return (await this.request({
      url: `api/monetization/adtags/adunit/${id}/upload`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    })).data
  }
}
