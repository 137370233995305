import { EnsureExtends } from '@lasso/shared/types'

import { SortConfig } from './SortConfig'
import { SupplyMediaTypeENUM } from './SupplyMediaTypeENUM'
import { PassbackTypeENUM } from './PassbackTypeENUM'
import { PiggybackPixelTypeENUM } from './PiggybackPixelTypeENUM'

export type AdUnitsListRequest = {
  pager: {
    pagesize: number
    page: number
  }
  order?: SortConfig
  filter?: AdUnitsFilter
  mediaType?: SupplyMediaTypeENUM
}

export type AdUnitsFilter = Partial<Record<string, string | number | null>>

export type AdUnitsListResponse = {
  data: AdUnitsListItem[]
  total: number
  page: {
    size: number
    number: number
  }
}

export type AdUnitsListItem = {
  id: number
  name: string
  cpm?: number
  impressions?: number
  fillRate?: number
  revenue?: number
  width: number
  height: number
  comments?: unknown
  publisherFeeType?: unknown
  publisherFeeAmount?: unknown
  placement: AdUnitsListItemEntity
  demandType?: AdUnitsListItemEntity[]
  reservePrice?: number
  piggybackPixelType?: AdUnitsListItemEntity<PiggybackPixelTypeENUM>
  piggybackPixelUrl?: string
  mediaType?: AdUnitsListItemEntity<SupplyMediaTypeENUM>
  trackingCode?: string
  trackingTag: string
  passback?: AdUnitsPassback
}

export type AdUnitsPassback = {
  name: string
  trackingCode?: string
  type: AdUnitsListItemEntity<PassbackTypeENUM>
  url: string | null
  width: number
  height: number
  creativePath?: string
  thirdPartyTag?: string
}

export type AdUnitsListItemEntity<Id = number> = {
  name: string
  id: Id
}

export type AdUnitsCreateUpdateRequest = {
  id?: number
  name: string
  cpm?: number
  impressions?: number
  fillRate?: number
  revenue?: number
  width?: number
  height?: number
  comments?: unknown
  publisherFeeType?: unknown
  publisherFeeAmount?: unknown
  placement: AdUnitsListItemEntity | number
  demandType?: AdUnitsListItemEntity[] | number
  reservePrice?: unknown
  piggybackPixelType?: AdUnitsListItemEntity<PiggybackPixelTypeENUM> | PiggybackPixelTypeENUM
  piggybackPixelUrl?: string
  mediaType?: AdUnitsListItemEntity<SupplyMediaTypeENUM> | SupplyMediaTypeENUM
  trackingCode?: string
  passback?: AdUnitsCreateUpdatePassback
}

export type AdUnitsCreateUpdateRequestStrict = EnsureExtends<{
  id?: number
  name: string
  cpm?: number
  impressions?: number
  fillRate?: number
  revenue?: number
  width?: number
  height?: number
  comments?: unknown
  publisherFeeType?: unknown
  publisherFeeAmount?: unknown
  placement: AdUnitsListItemEntity | number
  // DemandMediaTypeENUM bitfield
  demandType: AdUnitsListItemEntity[] | number
  reservePrice?: unknown
  piggybackPixelType: AdUnitsListItemEntity<PiggybackPixelTypeENUM> | PiggybackPixelTypeENUM | undefined
  piggybackPixelUrl: string
  mediaType: AdUnitsListItemEntity<SupplyMediaTypeENUM> | SupplyMediaTypeENUM
  trackingCode: string
  passback?: AdUnitsCreateUpdatePassback
}, AdUnitsCreateUpdateRequest>

export type AdUnitsCreateUpdatePassback = {
  name: string
  trackingCode?: string
  type: AdUnitsListItemEntity<PassbackTypeENUM> | PassbackTypeENUM
  url: string | null
  width?: number
  height?: number
  creativePath?: string
  thirdPartyTag?: string
}

export type AdUnitsCreateUpdateResponse = number

export type AdUnitsDeleteResponse = number

export type AdUnitsGetCsvResponse = BlobPart

export type AdUnitsGetManyCsvRequest = {
  ids?: string[]
  filter?: AdUnitsFilter
  eNewsIdentitySource?: number
  adUnitExportFormat?: number
}
export type AdUnitsGetManyCsvResponse = BlobPart

export type AdUnitsCreateManyRequest = Array<{
  name: string
  mediaType: SupplyMediaTypeENUM
  // DemandMediaTypeENUM bitfield
  demandType: number
  placement: number
  width?: number
  height?: number
  passbackUploadValue?: string | null
}>

export enum AdUnitExportTypeENUM {
  Standard = 1,
  Prebid = 2,
  Mobile = 3,
}
