import { ENewsIdentitySourceType } from '../../generated'

export enum ENewsIdentitySourceENUM {
  Email = 1,
  EmailHashed = 2,
  Npi = 4,
  NpiHashed = 8,
  Dgid = 16,
}

export const eNewsIdentitySourceToLegacy: Record<ENewsIdentitySourceType, ENewsIdentitySourceENUM> = {
  [ENewsIdentitySourceType.EMAIL]: ENewsIdentitySourceENUM.Email,
  [ENewsIdentitySourceType.EMAIL_HASHED]: ENewsIdentitySourceENUM.EmailHashed,
  [ENewsIdentitySourceType.NPI]: ENewsIdentitySourceENUM.Npi,
  [ENewsIdentitySourceType.NPI_HASHED]: ENewsIdentitySourceENUM.NpiHashed,
  [ENewsIdentitySourceType.DGID]: ENewsIdentitySourceENUM.Dgid,
}
